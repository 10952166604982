export default function Crash() {

    return(<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_109_7750)">
    <path d="M5.18112 18.8067C5.69636 18.2778 5.98257 17.5674 5.97778 16.829C5.973 16.0907 5.6776 15.384 5.15555 14.8619C4.6335 14.3398 3.92682 14.0443 3.18851 14.0395C2.4502 14.0346 1.73968 14.3208 1.21081 14.836C0.429558 15.6172 0.122136 16.8281 0.0244794 17.9219C-0.0310046 18.5421 -0.0274731 19.1661 0.0350263 19.7856C0.0518232 19.9418 0.201042 19.9758 0.226823 19.9809H0.234245C0.297136 19.9832 1.1944 20.0008 1.47096 19.127C1.5203 18.9637 1.59321 18.8086 1.68737 18.6664C1.86823 18.3996 2.28503 18.5602 2.23893 18.8793C2.19407 19.212 2.26289 19.55 2.43424 19.8387C2.45322 19.8707 2.48132 19.8962 2.51495 19.9121C2.54857 19.928 2.58617 19.9334 2.62292 19.9278C3.56081 19.7863 4.52292 19.4649 5.18112 18.8067Z" fill="#7179A5"/>
    <path d="M14.2783 15.6203V13.2003C14.5684 12.9511 14.8521 12.6884 15.1295 12.4121L15.8982 11.6445C18.7748 8.76635 20.1791 5.07807 19.9818 1.76479C19.9236 0.790177 19.2271 0.0933019 18.2521 0.0350988C14.9408 -0.162167 11.2498 1.24213 8.37246 4.11986L7.60488 4.88744C7.32832 5.16401 7.06556 5.44786 6.8166 5.739H4.39668C3.88301 5.75854 3.42871 6.05502 3.17168 6.48979L1.74902 9.33588C1.71157 9.43806 1.68537 9.54403 1.6709 9.6519C1.67313 9.83468 1.74673 10.0093 1.87599 10.1386C2.00524 10.2679 2.17991 10.3415 2.3627 10.3437H4.15957C3.9252 11.1288 4.08145 11.9007 4.58066 12.3992L7.61699 15.4359C8.11543 15.9347 8.8873 16.0902 9.67246 15.857V17.6538C9.67469 17.8366 9.74829 18.0113 9.87755 18.1406C10.0068 18.2698 10.1815 18.3434 10.3643 18.3456C10.4721 18.3312 10.5781 18.305 10.6803 18.2675L13.5256 16.8449C13.9619 16.5886 14.2576 16.1328 14.2783 15.6203ZM12.6092 7.40775C12.2079 7.00644 11.9346 6.49514 11.8239 5.93851C11.7132 5.38187 11.77 4.80491 11.9872 4.28058C12.2044 3.75625 12.5722 3.30809 13.0441 2.99279C13.516 2.67749 14.0707 2.5092 14.6383 2.5092C15.2058 2.5092 15.7606 2.67749 16.2325 2.99279C16.7044 3.30809 17.0722 3.75625 17.2894 4.28058C17.5066 4.80491 17.5634 5.38187 17.4527 5.93851C17.342 6.49514 17.0687 7.00644 16.6674 7.40775C16.4009 7.67423 16.0846 7.8856 15.7364 8.02982C15.3883 8.17403 15.0151 8.24826 14.6383 8.24826C14.2614 8.24826 13.8883 8.17403 13.5401 8.02982C13.192 7.8856 12.8756 7.67423 12.6092 7.40775Z" fill="#7179A5"/>
    </g>
    <defs>
    <clipPath id="clip0_109_7750">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
</svg>)
}