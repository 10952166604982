export default function Roulette() {

    return(<svg width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6943 6.8127C17.0762 7.61144 17.3192 8.4886 17.3919 9.41298H19.9999C19.9209 8.03652 19.5651 6.71233 18.9582 5.50562L16.6943 6.8127Z" fill="white"/>
    <path d="M3.90209 5.78615C4.41435 5.04713 5.05723 4.40534 5.79723 3.89437L4.49026 1.63062C3.92991 2.00115 3.40258 2.43057 2.91662 2.91653C2.47463 3.35851 2.07969 3.83489 1.73291 4.33906L3.90209 5.78615Z" fill="white"/>
    <path d="M5.79739 16.1055C5.05371 15.592 4.40813 14.9464 3.89462 14.2028L1.63086 15.5097C2.00139 16.0701 2.43081 16.5974 2.91678 17.0834C3.4027 17.5693 3.93007 17.9987 4.49042 18.3693L5.79739 16.1055Z" fill="white"/>
    <path d="M16.1054 14.2029C15.5919 14.9466 14.9463 15.5921 14.2026 16.1056L15.5096 18.3694C16.07 17.9988 16.5973 17.5694 17.0833 17.0835C17.5692 16.5976 17.9986 16.0702 18.3692 15.5098L16.1054 14.2029Z" fill="white"/>
    <path d="M3.30558 13.1872C2.92374 12.3885 2.6807 11.5113 2.60799 10.5869H0C0.0790051 11.9634 0.434783 13.2876 1.0417 14.4943L3.30558 13.1872Z" fill="white"/>
    <path d="M13.1872 16.6943C12.3885 17.0762 11.5113 17.3192 10.5869 17.3919V19.9999C11.9634 19.9209 13.2876 19.5651 14.4943 18.9582L13.1872 16.6943Z" fill="white"/>
    <path d="M17.3919 10.587C17.3192 11.5113 17.0761 12.3885 16.6943 13.1872L18.9582 14.4943C19.5651 13.2876 19.9209 11.9634 19.9999 10.5869H17.3919V10.587Z" fill="white"/>
    <path d="M9.41323 17.392C8.48884 17.3193 7.61169 17.0763 6.81295 16.6945L5.50586 18.9584C6.71258 19.5653 8.03672 19.921 9.41323 20.0001V17.392Z" fill="white"/>
    <path d="M10.5869 2.60799C11.5113 2.68074 12.3885 2.92378 13.1872 3.30558L14.4943 1.04166C13.2876 0.434822 11.9634 0.0790051 10.5869 0V2.60799Z" fill="white"/>
    <path d="M14.2026 3.89437C14.9463 4.40789 15.5919 5.05347 16.1054 5.79715L18.3692 4.49018C17.9986 3.92983 17.5692 3.4025 17.0833 2.91653C16.5973 2.43061 16.07 2.00118 15.5096 1.63062L14.2026 3.89437Z" fill="white"/>
    <path d="M6.81295 3.30558C7.61169 2.92378 8.48884 2.68074 9.41323 2.60799V0C8.03672 0.0790051 6.71258 0.434822 5.50586 1.0417L6.81295 3.30558Z" fill="white"/>
    <path d="M2.60799 9.413C2.68105 8.48474 2.92573 7.60402 3.31031 6.80258L1.12505 5.34473C0.467692 6.59429 0.0825269 7.97498 0 9.413H2.60799Z" fill="white"/>
    <path d="M10.0001 3.75867C6.55864 3.75867 3.75879 6.55848 3.75879 9.99995C3.75879 13.4414 6.55864 16.2412 10.0001 16.2412C13.4415 16.2412 16.2414 13.4414 16.2414 9.99995C16.2414 6.55848 13.4415 3.75867 10.0001 3.75867ZM12.3329 9.99995C12.3329 10.4223 12.2211 10.8273 12.012 11.1818L13.3732 12.543C13.6024 12.7722 13.6024 13.1438 13.3732 13.3731C13.2586 13.4877 13.1084 13.545 12.9582 13.545C12.808 13.545 12.6577 13.4877 12.5432 13.3731L11.182 12.0119C10.8275 12.2209 10.4225 12.3328 10.0001 12.3328C9.57778 12.3328 9.17269 12.221 8.81825 12.0119L7.45708 13.3731C7.34247 13.4877 7.19221 13.545 7.04206 13.545C6.89184 13.545 6.74161 13.4877 6.62704 13.3731C6.39781 13.1438 6.39781 12.7722 6.62704 12.543L7.98821 11.1818C7.77917 10.8273 7.66737 10.4223 7.66737 9.99995C7.66737 9.57762 7.77917 9.17257 7.98821 8.81809L6.62704 7.45692C6.39781 7.22773 6.39781 6.85607 6.62704 6.62684C6.85623 6.39761 7.22789 6.39761 7.45712 6.62684L8.81829 7.988C9.17277 7.77897 9.57782 7.66713 10.0002 7.66713C10.4225 7.66713 10.8276 7.77893 11.182 7.988L12.5432 6.62684C12.7724 6.39761 13.144 6.39765 13.3733 6.62684C13.6025 6.85607 13.6025 7.22769 13.3733 7.45692L12.0121 8.81809C12.2211 9.17257 12.3329 9.57762 12.3329 9.99995Z" fill="white"/>
    <path d="M10.0002 8.84167C9.7034 8.84167 9.40663 8.95465 9.18073 9.18055C8.96183 9.39945 8.84131 9.69046 8.84131 9.99999C8.84131 10.3095 8.96183 10.6006 9.18073 10.8194C9.63257 11.2713 10.3678 11.2713 10.8196 10.8194C11.0385 10.6005 11.1591 10.3095 11.1591 9.99999C11.1591 9.69046 11.0385 9.39941 10.8196 9.18055C10.5937 8.95465 10.2969 8.84167 10.0002 8.84167Z" fill="white"/>
</svg>)
}