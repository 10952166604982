export default function CaseOpenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_11646_88106)">
        <path
          d="M15.9867 4.103H2.01329C1.72206 4.103 1.48595 3.87814 1.48595 3.60077V1.25056C0.613793 1.59311 0 2.41048 0 3.36362V5.51819H18V3.36362C18 2.41048 17.3862 1.59311 16.5141 1.25059V3.6008C16.5141 3.87814 16.278 4.103 15.9867 4.103Z"
          fill="white"
        />
        <path
          d="M2.54064 1.07812H15.4594V3.0982H2.54064V1.07812ZM11.4328 7.93751H15.9867C16.278 7.93751 16.5141 8.16238 16.5141 8.43974V14.1518C16.5141 14.4292 16.278 14.654 15.9867 14.654H2.01329C1.72206 14.654 1.48595 14.4292 1.48595 14.1518V8.43974C1.48595 8.16238 1.72206 7.93751 2.01329 7.93751H6.56719V6.52232H0V16.0692H18V6.52232H11.4328V7.93751Z"
          fill="white"
        />
        <path
          d="M6.62529 8.94141H2.54129V13.649H15.4601V8.94141H11.3759C11.1343 9.97835 10.1611 10.7561 9.00066 10.7561C7.84036 10.7561 6.86657 9.97828 6.62529 8.94141Z"
          fill="white"
        />
        <path
          d="M10.3768 8.43974V6.52232H7.62054V8.43974C7.68772 10.1799 10.3107 10.1777 10.3768 8.43974Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11646_88106">
          <rect width="18" height="17.1429" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
