function MinesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill="#7D7E97"
        d="M17.713 1.501c-1.559-.998-2.886-.799-4.042-.14C12.66.497 11.711.47 11.711.47l-1.443 1.443C7.601.63 4.308 1.086 2.098 3.296a7.167 7.167 0 0 0 10.135 10.135c2.21-2.21 2.668-5.504 1.386-8.17l1.443-1.443s-.02-.678-.545-1.498c.799-.384 1.59-.373 2.526.227a.622.622 0 0 0 .67-1.046M6.667 3.925c-.14.03-3.457.766-3.91 3.833a.621.621 0 1 1-1.23-.184c.582-3.934 4.712-4.83 4.888-4.866a.623.623 0 0 1 .645.95.62.62 0 0 1-.393.267m2.128-.395a.615.615 0 1 1-.87-.87.615.615 0 0 1 .87.87"
      ></path>
    </svg>
  );
}

export default MinesIcon;
