import * as React from "react";
const SportsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20" // Add viewport
    fill="none"
    {...props}
  >
    <g fill="#7179A5">
      <path d="M10.814 1.616c.226-.3.19-.936.158-1.497l-.004-.072A9.973 9.973 0 0 0 6.47.645a23.286 23.286 0 0 1 3.347 1.984c.46-.396.808-.762.997-1.013ZM15.88 7.322a6.623 6.623 0 0 0-1.263.25c1.7 2.967 2.362 6.715 2.524 9.431a9.964 9.964 0 0 0 2.854-6.603c-1.16-1.426-2.697-3.24-4.114-3.078ZM5.778 4.76c1.194-.248 2.316-.849 3.21-1.483-1.866-1.285-3.422-1.952-3.732-2.08A10.038 10.038 0 0 0 .87 5.933c1.172-.467 2.44-.704 3.686-.936.403-.075.82-.152 1.222-.235Z" />
      <path d="M12.69 5.051c.107.107.213.216.318.327.39.41.743.857 1.068 1.33.471-.178 1.027-.305 1.692-.38 1.568-.177 2.961 1.064 4.157 2.431a9.958 9.958 0 0 0-2.469-5.426c-.723.087-1.529.234-2.35.48-.539.16-1.397.597-2.416 1.238ZM11.374 10.216c.41-1.39.886-2.391 1.806-3.041a9.348 9.348 0 0 0-.895-1.107 19.03 19.03 0 0 0-.451-.456C8.14 8.126 3.082 12.743 2.622 16.744a9.977 9.977 0 0 0 6.003 3.159c.86-1.715 1.672-5.201 2.153-7.274.232-.996.432-1.856.596-2.413Z" />
      <path d="M7.187 8.013a35.669 35.669 0 0 1 3.891-3.106c-.414-.367-.83-.705-1.239-1.015-1.033.776-2.385 1.541-3.857 1.848-.413.085-.835.164-1.243.24-1.572.292-3.082.574-4.352 1.283A10 10 0 0 0 .005 10c0 2.124.664 4.092 1.793 5.712.732-2.897 3.241-5.72 5.389-7.7ZM11.94 4.336c1.19-.759 2.215-1.283 2.88-1.482a14.777 14.777 0 0 1 1.74-.403A9.962 9.962 0 0 0 11.977.196c.038.698.055 1.465-.365 2.022-.2.264-.538.627-.977 1.02.43.336.867.701 1.304 1.098ZM12.333 10.499c-.156.529-.353 1.375-.58 2.356-.673 2.895-1.317 5.485-2.069 7.137.107.003.214.008.321.008 2.33 0 4.473-.8 6.173-2.136-.067-2.842-.748-6.862-2.464-9.845-.666.494-1.024 1.272-1.38 2.48Z" />
    </g>
  </svg>
);
export default SportsIcon;
