function KenoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
    >
      <path
        fill="#7D7E97"
        stroke="#0F1328"
        strokeWidth="0.2"
        d="M5.682.154h8.605L12.68 2.937H7.29L5.681.154zm9.827.705l4.302 7.452h-3.214l-2.695-4.668L15.509.859zM.16 8.311L4.46.86l1.608 2.784L3.372 8.31H.158zm5.908 6.08L4.46 17.173.158 9.722h3.214l2.695 4.668zm8.22 3.489H5.682l1.608-2.784h5.39l1.607 2.784zm1.222-.706l-1.607-2.783 2.695-4.67 3.214.001-4.302 7.452zm-2.83-12.826l2.696 4.669-2.695 4.668H7.29L4.594 9.017 7.29 4.348h5.39z"
      ></path>
    </svg>
  );
}

export default KenoIcon;
