function MiniBattleIcon() {
  return (
    <svg
      width={18}
      height={18}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.6 33.98"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="17.3"
          x2="17.3"
          y1="35.96"
          y2="1.99"
          gradientTransform="matrix(1 0 0 -1 0 35.96)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#87c1f2"></stop>
          <stop offset="1" stopColor="#3a8dda"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="14.67"
          x2="14.67"
          y1="35.96"
          y2="7.21"
          gradientTransform="matrix(1 0 0 -1 0 35.96)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#87c1f2"></stop>
          <stop offset="1" stopColor="#3a8dda"></stop>
        </linearGradient>
      </defs>
      <g strokeWidth="0">
        <g>
          <path
            fill="#2099ff"
            d="M33.52 27.73l-4.74-4.65-5.25 5.16 4.74 4.65c.35.34.75.61 1.21.79.45.18.93.28 1.42.28s.97-.09 1.42-.28c.45-.18.86-.45 1.21-.79s.62-.74.81-1.18c.19-.44.28-.92.28-1.4s-.1-.95-.28-1.4c-.19-.44-.46-.84-.81-1.18zm-18.6 1.03c.2 0 .4-.06.56-.17.17-.11.3-.26.37-.45.08-.18.1-.38.06-.58a1.03 1.03 0 00-.28-.51l-1.19-1.17L34.22 10c.12-.09.21-.21.27-.34.06-.13.1-.28.1-.42V.99a.995.995 0 00-.3-.7c-.09-.09-.21-.17-.33-.22s-.25-.08-.39-.08h-8.4c-.15 0-.3.03-.43.1-.14.06-.26.16-.35.27L8.23 19.79l-1.19-1.17c-.19-.19-.45-.29-.72-.29-.27 0-.53.11-.72.29-.19.19-.3.44-.3.7 0 .26.11.52.3.7l8.6 8.44c.09.09.21.17.33.22.12.05.25.08.39.08zM28.29 4.79a1.013 1.013 0 011.11-.21c.13.05.23.12.33.22a.995.995 0 01.3.7.995.995 0 01-.3.7L12.06 23.54l-1.43-1.41L28.3 4.79zM6.37 32.88l4.74-4.65-5.25-5.16-4.74 4.66c-.35.34-.63.74-.82 1.18a3.536 3.536 0 00-.02 2.8c.19.45.46.85.81 1.19a3.725 3.725 0 002.64 1.06c.49 0 .98-.1 1.43-.29s.86-.46 1.21-.8z"
          ></path>
          <path
            fill="url(#linear-gradient)"
            d="M33.52 27.73l-4.74-4.65-5.25 5.16 4.74 4.65c.35.34.75.61 1.21.79.45.18.93.28 1.42.28s.97-.09 1.42-.28c.45-.18.86-.45 1.21-.79s.62-.74.81-1.18c.19-.44.28-.92.28-1.4s-.1-.95-.28-1.4c-.19-.44-.46-.84-.81-1.18zm-18.6 1.03c.2 0 .4-.06.56-.17.17-.11.3-.26.37-.45.08-.18.1-.38.06-.58a1.03 1.03 0 00-.28-.51l-1.19-1.17L34.22 10c.12-.09.21-.21.27-.34.06-.13.1-.28.1-.42V.99a.995.995 0 00-.3-.7c-.09-.09-.21-.17-.33-.22s-.25-.08-.39-.08h-8.4c-.15 0-.3.03-.43.1-.14.06-.26.16-.35.27L8.23 19.79l-1.19-1.17c-.19-.19-.45-.29-.72-.29-.27 0-.53.11-.72.29-.19.19-.3.44-.3.7 0 .26.11.52.3.7l8.6 8.44c.09.09.21.17.33.22.12.05.25.08.39.08zM28.29 4.79a1.013 1.013 0 011.11-.21c.13.05.23.12.33.22a.995.995 0 01.3.7.995.995 0 01-.3.7L12.06 23.54l-1.43-1.41L28.3 4.79zM6.37 32.88l4.74-4.65-5.25-5.16-4.74 4.66c-.35.34-.63.74-.82 1.18a3.536 3.536 0 00-.02 2.8c.19.45.46.85.81 1.19a3.725 3.725 0 002.64 1.06c.49 0 .98-.1 1.43-.29s.86-.46 1.21-.8z"
          ></path>
        </g>
        <g>
          <path
            fill="#2099ff"
            d="M.4 10.01l8.01 6.43 3.12-3.75-6.62-6.5a.995.995 0 01-.3-.7.995.995 0 01.3-.7c.09-.09.21-.17.33-.22.12-.05.25-.08.39-.08a1.046 1.046 0 01.72.3l6.48 6.36 3.2-3.83L10.22.37A.942.942 0 009.87.1C9.73.04 9.59 0 9.44 0H1.03C.76 0 .5.1.31.29.13.48.02.73.02.99v8.25a.993.993 0 00.37.77zm27.18 8.61l-1.19 1.17-.46-.55-2.69 2.16.75.74-1.43 1.41-.9-.88-2.76 2.21 1.26 1.01-1.19 1.17c-.19.19-.3.44-.3.7 0 .26.11.52.3.7.19.19.45.29.72.29.27 0 .53-.11.72-.29l8.6-8.44a.995.995 0 00.3-.7.995.995 0 00-.3-.7c-.19-.19-.45-.29-.72-.29s-.53.1-.72.29z"
          ></path>
          <path
            fill="url(#linear-gradient-2)"
            d="M.4 10.01l8.01 6.43 3.12-3.75-6.62-6.5a.995.995 0 01-.3-.7.995.995 0 01.3-.7c.09-.09.21-.17.33-.22.12-.05.25-.08.39-.08a1.046 1.046 0 01.72.3l6.48 6.36 3.2-3.83L10.22.37A.942.942 0 009.87.1C9.73.04 9.59 0 9.44 0H1.03C.76 0 .5.1.31.29.13.48.02.73.02.99v8.25a.993.993 0 00.37.77zm27.18 8.61l-1.19 1.17-.46-.55-2.69 2.16.75.74-1.43 1.41-.9-.88-2.76 2.21 1.26 1.01-1.19 1.17c-.19.19-.3.44-.3.7 0 .26.11.52.3.7.19.19.45.29.72.29.27 0 .53-.11.72-.29l8.6-8.44a.995.995 0 00.3-.7.995.995 0 00-.3-.7c-.19-.19-.45-.29-.72-.29s-.53.1-.72.29z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default MiniBattleIcon;
