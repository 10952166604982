export default function Plinko() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 4.60938C11.75 6.12816 10.5188 7.35938 9 7.35938C7.48122 7.35938 6.25 6.12816 6.25 4.60938C6.25 3.09059 7.48122 1.85937 9 1.85937C10.5188 1.85937 11.75 3.09059 11.75 4.60938Z"
        stroke="#E8E5FF"
        strokeOpacity="0.5"
        strokeWidth="1.5"
      />
      <path
        d="M4 16.6094C5.933 16.6094 7.5 15.0424 7.5 13.1094C7.5 11.1764 5.933 9.60938 4 9.60938C2.067 9.60938 0.5 11.1764 0.5 13.1094C0.5 15.0424 2.067 16.6094 4 16.6094Z"
        fill="#E8E5FF"
        fillOpacity="0.5"
      />
      <path
        d="M14 16.6094C15.933 16.6094 17.5 15.0424 17.5 13.1094C17.5 11.1764 15.933 9.60938 14 9.60938C12.067 9.60938 10.5 11.1764 10.5 13.1094C10.5 15.0424 12.067 16.6094 14 16.6094Z"
        fill="#E8E5FF"
        fillOpacity="0.5"
      />
    </svg>
  );
}
